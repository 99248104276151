<template>
  <div class="container">
    <a-page-header title="项目详情" @back="() => {$router.go(-1)}">
      <template slot="extra">
        <a-button type="primary" @click="handlePrintTask">打印</a-button>
      </template>
    </a-page-header>
    <div class="wrapper">
      <a-steps v-model="taskData.step" type="navigation" status="finish">
        <a-step title="发布项目">
          <span style="white-space:nowrap;" slot="description">{{taskData.add_time | formateDate()}}</span>
        </a-step>
        <a-step title="支付费用">
          <span style="white-space:nowrap;" slot="description">{{taskData.pay_time | formateDate()}}</span>
        </a-step>
        <a-step title="发放服务费">
          <span style="white-space:nowrap;" slot="description">{{taskData.yi_pay_time | formateDate()}}</span>
        </a-step>
        <a-step title="项目完成">
          <span style="white-space:nowrap;" slot="description">{{taskData.yi_sure_time | formateDate()}}</span>
        </a-step>
      </a-steps>
      <a-alert type="info">
        <div slot="message">
          订单状态：{{taskData.status | formateStatus()}}
        </div>
      </a-alert>
      <a-form :form="form" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-divider orientation="left">订单信息</a-divider>
        <a-row>
          <a-col :span="8">
            <a-form-item label="项目名称">
              <div>{{taskData.task_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开始时间">
              <div>{{taskData.start_time | formateDate(true)}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="项目周期">
              <div>{{taskData.day}}天</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="项目服务费">
              <div>¥{{taskData.task_money}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.bing && taskData.bing.length > 0">
            <a-form-item label="结算人数">
              <div>{{taskData.bing.length}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="项目类型">
              <div>{{taskData.g_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="税收编码">
              <div>{{taskData.code}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="税率">
              <div>{{taskData.tax | formateTax()}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单编号">
              <div>{{taskData.order_no}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.add_time">
            <a-form-item label="创建时间">
              <div>{{taskData.add_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.bing_time">
            <a-form-item label="接单时间">
              <div>{{taskData.bing_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.complete_time">
            <a-form-item label="完成任务时间">
              <div>{{taskData.complete_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.pay_time">
            <a-form-item label="公司付款时间">
              <div>{{taskData.pay_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.sure_pay_time">
            <a-form-item label="服务商收款时间">
              <div>{{taskData.sure_pay_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.apply_time">
            <a-form-item label="公司申请开票时间">
              <div>{{taskData.apply_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.sure_apply_time">
            <a-form-item label="服务商开票时间">
              <div>{{taskData.sure_apply_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.yi_pay_time">
            <a-form-item label="服务商付款时间">
              <div>{{taskData.yi_pay_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="taskData.yi_sure_time">
            <a-form-item label="分包商收款时间">
              <div>{{taskData.yi_sure_time | formateDate()}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="项目标签" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }" v-if="taskData.status == 0 && userInfo.role_id == 3">
          <a-checkbox-group @change="handleLableChange" v-model="checkedLable">
            <a-checkbox :value="item.id" v-for="item in lableData" :key="item.id">{{item.name}}</a-checkbox>
          </a-checkbox-group>
          <a-input
            type="hidden"
            v-decorator="['lable', {initialValue: lable, rules: [{required: true, message: '请输入项目标签'}]}]"
          />
        </a-form-item>
        <a-form-item label="项目标签" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }" v-else>
          <div><a-tag v-for="item in taskData.lable_name" :key="item.id">{{item.name}}</a-tag></div>
        </a-form-item>
        <a-divider orientation="left">公司信息</a-divider>
        <a-row>
          <a-col :span="8">
            <a-form-item label="公司名称">
              <div>{{taskData.u_company_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="统一信用代码">
              <div>{{taskData.u_credit_code}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="公司法人">
              <div>{{taskData.u_real_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系电话">
              <div>{{taskData.u_phone}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="公司地址">
              <div>{{taskData.u_address}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开户银行">
              <div>{{taskData.u_bank}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="银行账号">
              <div>{{taskData.u_bank_code}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider orientation="left">服务商信息</a-divider>
        <a-row>
          <a-col :span="8">
            <a-form-item label="服务商">
              <div>{{taskData.c_real_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="服务商主体">
              <div>{{taskData.c_company_name}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系电话">
              <div>{{taskData.c_phone}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="公司地址">
              <div>{{taskData.c_address}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开户银行">
              <div>{{taskData.c_bank}}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="银行账号">
              <div>{{taskData.c_bank_code}}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider orientation="left">附件</a-divider>
        <a-form-item label="附件" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }">
          <div>
            <div class="fj-item" v-for="(item, index) in taskData.fj" :key="index">
              <a :href="item.url" target="_blank">
                <img :src="item.url" v-if="item.type == 'img'" />
                <a-icon class="icon" v-else type="file" theme="filled" />
              </a>
            </div>
          </div>
        </a-form-item>
        <template v-if="taskData.status > 0">
          <a-divider orientation="left">项目人员信息</a-divider>
          <a-button type="primary" v-if="taskData.status == 6 && userInfo.role_id == 2"><a :href="'/home/bing_excel_out?task_id=' + taskData.id">导出项目人员信息</a></a-button>
          <table style="width: 100%;border:1px solid #e8e8e8;">
            <thead class="ant-table-thead">
              <tr>
                <th>姓名</th>
                <th>身份证号</th>
                <th>手机号码</th>
                <th>服务费用</th>
                <th>开户银行</th>
                <th>银行账号</th>
              </tr>
            </thead>
            <tbody class="ant-table-tbody">
              <tr v-for="item in taskData.bing" :key="item.id">
                <td>{{item.real_name}}</td>
                <td>{{item.id_code}}</td>
                <td>{{item.phone}}</td>
                <td>{{item.money}}</td>
                <td>{{item.bank}}</td>
                <td>{{item.bank_code}}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-if="taskData.status == 0 && userInfo.role_id == 3">
          <a-divider orientation="left">项目人员信息</a-divider>
          <a-form-item>
            <a-button type="primary" @click="handleShowChooseBingModal">选择人员</a-button>
          </a-form-item>
          <a-alert type="info">
            <div slot="message">
              已选择<span style="color:blue;"> {{selectedBingList.length}} </span>人，
              已分配金额<span style="color:orange;"> {{allocatedMoney | formateMoney()}} </span>元，
              未分配金额
              <span style="color:orange;" v-if="allocatedMoney == 0 && unallocatedMoney == 0"> {{taskData.task_money}} </span>
              <span style="color:orange;" v-else> {{unallocatedMoney | formateMoney()}} </span>
              元
              <span style="color:red;margin-left: 5px;" v-if="unallocatedMoney < 0">可分配金额不足</span>
            </div>
          </a-alert>
          <table style="width: 100%;border:1px solid #e8e8e8;">
            <thead class="ant-table-thead">
              <tr>
                <th>姓名</th>
                <th>身份证号</th>
                <th>手机号码</th>
                <th>服务费用</th>
                <th>开户银行</th>
                <th>银行账号</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody class="ant-table-tbody">
              <tr v-for="(item, index) in selectedBingList" :key="item.id">
                <td>{{item.real_name}}</td>
                <td>{{item.id_code}}</td>
                <td>{{item.phone}}</td>
                <td><a-input type="number" placeholder="请输入服务费用" v-model="item.money" @change="handleInputChange" /></td>
                <td>{{item.bank}}</td>
                <td>{{item.bank_code}}</td>
                <td>
                  <a-button size="small" icon="delete" @click="handleDeleteBing(index)"></a-button>
                </td>
              </tr>
            </tbody>
          </table>
          <a-form-item>
            <a-popconfirm title="确定要提交分包商吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleSubmitBing">
              <a-button style="margin-left:20%;" type="primary">提交</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
        <template v-if="taskData.cg">
          <a-divider orientation="left">项目成果</a-divider>
          <a-form-item label="项目成果" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }">
            <div class="upload-wrapper-item"><a :href="taskData.cg" target="_blank"><img :src="taskData.cg" alt="项目成果"></a></div>
          </a-form-item>
        </template>
        <template v-if="taskData.status == 1 && userInfo.role_id == 3">
          <a-divider orientation="left">项目成果</a-divider>
          <a-form-item label="项目成果" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }">
            <a-upload
              name="photo"
              class="upload-wrapper"
              :show-upload-list="false"
              action="/common/upload"
              @change="handleCgChange">
              <img v-if="cg" :src="cg" alt="项目成果" />
              <div class="empty" v-else>
                <a-icon class="icon" :type="loading ? 'loading' : 'picture'" />
                <div class="upload-text">点击上传图片</div>
                <div class="upload-text-tip">支持jpg/png格式，不超过2MB</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item>
            <a-popconfirm title="确定要上传项目成果吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleSubmitCg">
              <a-button style="margin-left:20%;" type="primary">上传成果</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
        <template v-if="taskData.status == 2 && userInfo.role_id == 1">
          <a-form-item>
            <a-popconfirm title="确定公司已付款吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleChangeStatus(3)">
              <a-button style="margin-left:10%;" type="primary">公司已付款</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
        <template v-if="taskData.status == 3 && userInfo.role_id == 2">
          <a-form-item>
            <a-popconfirm title="确定服务商已收款吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleChangeStatus(4)">
              <a-button style="margin-left:10%;" type="primary">服务商已收款</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
        <template v-if="taskData.status == 4 && userInfo.role_id == 1">
          <a-form-item>
            <a-popconfirm title="确定公司申请开票吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleChangeStatus(5)">
              <a-button style="margin-left:10%;" type="primary">公司申请开票</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
        <template v-if="taskData.kp_fj">
          <a-divider orientation="left">发票信息</a-divider>
          <a-form-item label="发票" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }">
            <div class="upload-wrapper-item"><a :href="taskData.kp_fj" target="_blank"><img :src="taskData.kp_fj" alt="发票"></a></div>
          </a-form-item>
        </template>
        <template v-if="taskData.status == 5 && userInfo.role_id == 2">
          <a-divider orientation="left">发票信息</a-divider>
          <a-form-item label="发票" :labelCol="{ lg: { span: 3 }, sm: { span: 3 } }" :wrapperCol="{ lg: { span: 21 }, sm: { span: 21 } }">
            <a-upload
              name="photo"
              class="upload-wrapper"
              :show-upload-list="false"
              action="/common/upload"
              @change="handleFpChange">
              <img v-if="kp_fj" :src="kp_fj" alt="发票" />
              <div class="empty" v-else>
                <a-icon class="icon" :type="loading ? 'loading' : 'picture'" />
                <div class="upload-text">点击上传图片</div>
                <div class="upload-text-tip">支持jpg/png格式，不超过2MB</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item>
            <a-popconfirm title="确定服务商确认开票吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleChangeStatus(6)">
              <a-button style="margin-left:10%;" type="primary">服务商确认开票</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
        <template v-if="taskData.status == 6 && userInfo.role_id == 2">
          <a-form-item>
            <a-popconfirm title="确定服务商已付款到分包商吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleChangeStatus(7)">
              <a-button style="margin-left:10%;" type="primary">服务商已付款到分包商</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
        <template v-if="taskData.status == 7 && userInfo.role_id == 3">
          <a-form-item>
            <a-popconfirm title="确定分包商已收款吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleChangeStatus(8)">
              <a-button style="margin-left:10%;" type="primary">分包商已收款</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
      </a-form>
    </div>
    <a-modal v-model="showChooseBingModal" title="选择人员" @ok="submitChoose" width="80%" :bodyStyle="{height: 'calc(80vh - 48px - 55px)', overflow: 'auto'}">
      <a-input style="width: 170px;" type="text" v-model="searchKey" placeholder="请输入人员姓名" /> 
      <a-button style="margin: 0 20px 20px 10px;" type="primary" @click="handleSearch">搜索</a-button> 
      <a-upload
        name="file"
        :show-upload-list="false"
        action="/common/upload_excel"
        @change="handleExcelIn">
        <a-button>批量导入人员</a-button>
      </a-upload>
      <a-alert type="info">
        <div slot="message">
          请使用对应的表格模板，否则会报错，无法批量导入！<a class="ant-btn ant-btn-primary" href="/common/storage?file_name=/app/public/示例.xlsx" target="_blank" rel="">下载模板</a>
        </div>
      </a-alert>
      <a-table
        row-key="id"
        :loading="loading"
        :columns="columns"
        :data-source="bingList"
        :pagination="pagination"
        @change="handleTableChange"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
      </a-table>
    </a-modal>
  </div>
</template>

<script>

import { taskDetail, userList, taskBing, taskCg, taskStatus, bingExcelIn, lableData } from '@/utils/api'
import { mapState } from 'vuex'
const columns = [
  {
    title: '姓名',
    dataIndex: 'real_name'
  },
  {
    title: '身份证',
    dataIndex: 'id_code'
  },
  {
    title: '手机号码',
    dataIndex: 'phone'
  },
  {
    title: '开户银行',
    dataIndex: 'bank'
  },
  {
    title: '银行账号',
    dataIndex: 'bank_code'
  }
]
let times = null
export default {
  name: 'TaskDetail',
  data() {
    return {
      labelCol: { lg: { span: 10 }, sm: { span: 10 } },
      wrapperCol: { lg: { span: 14 }, sm: { span: 14 } },
      form: this.$form.createForm(this),
      taskData: {},
      showChooseBingModal: false,
      columns,
      bingList: [],
      selectedBingList: [],
      selectedRowKeys: [],
      selectedRows: [],
      searchKey: '',
      tableParams: {},
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: total => `共${total}条数据`
      },
      allocatedMoney: 0,
      unallocatedMoney: 0,
      cg: '',
      kp_fj: '',
      lableData: [],
      lable: '',
      checkedLable: [],
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  filters: {
    formateMoney(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val.toFixed(2)
      }
      return '0.00'
    },
    formateDate(val, noDetail) {
      if (val) {
        const date = new Date(parseInt(val) * 1000)
        const y = date.getFullYear()
        const m = date.getMonth() + 1
        const d = date.getDate()
        const h = date.getHours()
        const i = date.getMinutes()
        const s = date.getSeconds()
        return y + '-'
              + (m < 10 ? '0' + m : m) + '-'
              + (d < 10 ? '0' + d : d) +
              (noDetail ? '' : (' '
              + (h < 10 ? '0' + h : h) + ':'
              + (i < 10 ? '0' + i : i) + ':'
              + (s < 10 ? '0' + s : s)
              ))
      }
      return ''
    },
    formateTax(val) {
      val = parseFloat(val)
      if (val + '' != '0' && val + '' != 'NaN') {
        return val * 100 + '%'
      }
      return ''
    },
    formateStatus(val) {
      let str = ''
      switch (val) {
        case 0:
          str = '已创建项目'
          break;
        case 1:
          str = '已接项目'
          break;
        case 2:
          str = '已完成项目'
          break;
        case 3:
          str = '公司已支付'
          break;
        case 4:
          str = '服务商已收款'
          break;
        case 5:
          str = '公司已申请开票'
          break;
        case 6:
          str = '服务商已开票'
          break;
        case 7:
          str = '服务已付款到分包商'
          break;
        case 8:
          str = '分包商已收款并开票'
          break;
      }
      return str
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getLableData () {
      lableData({
        type: 1,
        switch: 4
      }).then((res) => {
        const data = res.info || []
        let checkedLable = this.lable
        const checkedLableArr = []
        if (checkedLable && data.length > 0) {
          const lableArr = checkedLable.split(',')
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            for (let i = 0; i < lableArr.length; i++) {
              const lable = lableArr[i];
              if (item.id == lable) {
                checkedLableArr.push(item.id)
                break
              }
            }
          }
        }
        this.lableData = data
        this.checkedLable = checkedLableArr
      })
    },
    handleLableChange (e) {
      let lable = ''
      if (e.length > 0) {
        lable = ','
        for (let index = 0; index < e.length; index++) {
          const item = e[index];
          lable += item + ','
        }
      }
      this.lable = lable
    },
    handlePrintTask() {
      window.open(`/#/index/print/${this.taskData.id}`)
    },
    handleChangeStatus(status) {
      const _this = this
      const params = {
        task_id: this.taskData.id,
        status
      }
      if (status == 6) {
        if (this.kp_fj == '') {
          this.$error({
            title: '提示',
            content: '请先上传发票附件',
            okText: '确定'
          })
          return
        }
        params.kp_fj = this.kp_fj
      }
      taskStatus(params).then((res)=> {
        if (res.error == 0) {
          this.$success({
            title: '提示',
            content: '操作成功',
            okText: '确定',
            onOk() {
              _this.getData()
            }
          })
        } else {
          this.$error({
            title: '提示',
            content: res.info,
            okText: '确定'
          })
        }
      })
    },
    handleSubmitCg() {
      if (!this.cg) {
        this.$message.error('请先上传成果图片')
        return
      }
      const _this = this
      taskCg({
        task_id: this.taskData.id,
        cg: this.cg
      }).then((res)=>{
        if (res.error == 0) {
          this.$success({
            title: '提示',
            content: '上传成果成功',
            okText: '确定',
            onOk() {
              _this.getData()
            }
          })
        } else {
          this.$error({
            title: '提示',
            content: res.info,
            okText: '确定'
          })
        }
      })
    },
    handleCgChange(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        this.cg = res.file.response.info
        this.loading = false
      }
    },
    handleFpChange(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        this.kp_fj = res.file.response.info
        this.loading = false
      }
    },
    handleSubmitBing () {
      const selectedBingList = [...this.selectedBingList]
      const unallocatedMoney = this.unallocatedMoney
      if (selectedBingList.length <= 0) {
        this.$message.error('请先选择分包商')
        return
      }
      if (unallocatedMoney < 0) {
        this.$message.error('可分配金额不足，请重新分配')
        return
      }
      let bing = []
      for (let index = 0; index < selectedBingList.length; index++) {
        const item = selectedBingList[index];
        if (!item.money) {
          this.$error({
            title: '提示',
            content: '请输入服务费用',
            okText: '确定'
          })
          return
        }
        bing.push({
          user_id: item.id,
          money: item.money
        })
      }
      let lable = this.lable
      const _this = this
      taskBing({
        task_id: this.taskData.id,
        lable,
        bing: JSON.stringify(bing)
      }).then((res) => {
        if (res.error == 0) {
          this.$success({
            title: '提示',
            content: '提交成功',
            okText: '确定',
            onOk() {
              _this.getData()
            }
          })
        } else {
          this.$error({
            title: '提示',
            content: res.info,
            okText: '确定'
          })
        }
      })
    },
    handleDeleteBing (index) {
      this.selectedBingList.splice(index, 1)
      this.handleInputChange()
    },
    handleInputChange () {
      if (times) {
        clearTimeout(times)
      }
      times = setTimeout(()=>{
        const selectedBingList = [...this.selectedBingList]
        let allocatedMoney = 0
        let unallocatedMoney = parseFloat(this.taskData.task_money)
        for (let index = 0; index < selectedBingList.length; index++) {
          let money = parseFloat(selectedBingList[index].money);
          if (money + '' == 'NaN') {
            money = 0
          }
          allocatedMoney += money
          unallocatedMoney -= money
        }
        this.allocatedMoney = allocatedMoney
        this.unallocatedMoney = unallocatedMoney
        clearTimeout(times)
      }, 500)
    },
     handleSearch() {
      this.tableParams = {
        real_name: this.searchKey
      }
      const pagination = { ...this.pagination }
      pagination.current = 1,
      this.pagination = pagination
      this.getTableData()
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      this.selectedRowKeys = []
      const pagination = { ...this.pagination }
      userList({
        role_id: 4,
        pageCount: pagination.pageSize,
        page: pagination.current,
        ...this.tableParams
      }).then(res => {
        if (pagination.current == 1){
          pagination.total = res.count
          this.pagination = pagination
        }
        this.bingList = res.user
        this.loading = false
      })
    },
    onSelectChange(selectedRowKeys,selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    submitChoose() {
      const selectedRows = [...this.selectedRows]
      const selectedBingList = [...this.selectedBingList]
      for (let index = 0; index < selectedRows.length; index++) {
        let add = true
        const row = selectedRows[index];
        for (let i = 0; i < selectedBingList.length; i++) {
          const bing = selectedBingList[i];
          if (row.id == bing.id) {
            add = false
            break
          }
        }
        if (add) {
          row.money = ''
          this.selectedBingList.push(row)
        }
      }
      this.$message.success("添加成功")
      this.selectedRows = []
      this.selectedRowKeys = []
    },
    handleShowChooseBingModal() {
      this.searchKey = ''
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getTableData()
      this.showChooseBingModal = true
    },
    handleExcelIn(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        if (res.file.response.error == 0) {
          bingExcelIn({
            path: res.file.response.info,
          }).then(() => {
            this.$message.success("导入成功")
            this.handleSearch()
          })
        } else {
          this.loading = false
          this.$message.error(res.file.response.info)
        }
      }
    },
    getData() {
      const id = this.$route.query.id || ''
      taskDetail({
        id
      }).then(res => {
        const data = res.info
        if (data.status < 3) {
          data.step = 0
        } else if (data.status < 7) {
          data.step = 1
        } else if (data.status < 8) {
          data.step = 2
        } else if (data.status == 8) {
          data.step = 3
        }
        const arr = []
        data.fj = data.fj ? data.fj.split(',') : []
        for (let index = 0; index < data.fj.length; index++) {
          const item = data.fj[index];
          let type = 'img'
          if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(item)) {    
            type = 'file' 
          }   
          arr.push({
            url: item,
            type
          })
        }
        data.fj = arr
        this.lable = data.lable
        this.taskData = data
        this.getLableData()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
.upload-wrapper-item,
.upload-wrapper /deep/ > .ant-upload {
  width: 385px;
  height: 275px;
  border: 2px dotted #f6f6f6;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 6px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .empty {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666;
    background-color: #fff;
    .icon {
      font-size: 40px;
    }
    .upload-text {
      font-size: 16px;
    }
    .upload-text-tip {
      color: #999;
      font-size: 12px;
    }
  }
}
.fj-item {
  width: 120px;
  height: 120px;
  border: 1px solid #d8d8d8;
  padding: 5px;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;
  margin: 0 8px 10px 0;
  text-align: center;
  line-height: 120px;
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .icon {
    font-size: 90px;
    margin-top: 5px;
  }
}
@media print {
  .no-print {
    display: none !important;
  }
  .need-print {
    display: block !important;
  }
}
</style>
